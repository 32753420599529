

const getUrlQueryParams = () => {
  const queryParams = window.location.search.substring(1).split('&');
  let obj = {};
  queryParams.forEach(param => {
    const paramSplitted = param.split('=');
    obj[paramSplitted[0]] = paramSplitted[1];
  });
  return obj;
}

export default getUrlQueryParams;