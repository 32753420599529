import $ from "jquery";
import Toastify from "toastify-js";

import { deleteAuthCookie, getAuthCookie } from "./cookies.js";

const mapMessages = (messages) =>
  messages.map(({ text }) =>
    Toastify({
      text: text,
      duration: 6000,
    }).showToast()
  );

const transformResponse = (response, callback) => {
  if (response.code === 423) {
    mapMessages(["Too much  requests. Please try later"]);
  }
  if (response.message) {
    mapMessages(response.message);
  }
  if (response.code === 401) {
    deleteAuthCookie();
    if (window.location.pathname === "/tokens.html") {
      window.location.replace("/");
    }
  }
  if (response.code === 0) {
    callback(response);
  }
};

const fetchData = (url, params, callback) =>
  $.ajax({
    url: `${process.env.BASE_URL}${url}`,
    type: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    contentType: "application/json",
    data: JSON.stringify(params),
    // data: params,
    success: (response) => transformResponse(response, callback),
  });

export default fetchData;
