import 'aos/dist/aos.css';
import 'toastify-js/src/toastify.css';

import AOS from 'aos';

import authSso from './authSso.js';
import { getAuthCookie, setAuthCookie } from './cookies.js';
import getUrlQueryParams from './getUrlQueryParams';
import phrases from './phrases.js';
import fetchData from './requests.js';
import Vue from './vue.min';

const target = document.getElementById('preloader');
  target.style.opacity = '0';
  setTimeout(function() {
    target.remove();
  }, 1000);

AOS.init()
      
function getCoords(elem) {
  var box = elem.getBoundingClientRect();

  return {
    top: box.top + pageYOffset,
    left: box.left + pageXOffset
  };

}

Vue.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

const app = new Vue({
  el:'#app',
  data: {
    current_lang: 'en',
    solutionsIsActive: false,
    phrases: phrases,
    activeScenario: 0,
    activeRequest: 0,
    scrollOptions: {
      behavior: 'smooth',
    },
    sidebarIsActive: false,
    focus: '',
    round1: 1,
    round2: 1,
    round3: 1,
    round4: 1,
    round5: 1,
    round6: 1,
    name: '',
    phone: '',
    email: '',
    message: '',
    errors: {
      name: false,
      phone: false,
      email: false,
      message: false,
    },
    request1send: false,
    request2send: false,


    auth: getAuthCookie(),
    userInfo: {},
  },
  computed: {
    
  },
  methods: {
    buyTokensHandle: function() {
      if (this.auth) {
        window.location.replace('/tokens.html')
      } else {
        this.authSso();
      }
    },
    switchLang: function() {
      this.current_lang = this.current_lang === 'ru' ? 'en' : 'ru'
    },
    showSolutions: function(flag) {
      this.solutionsIsActive = flag ? true : false;
    },
    setActiveScenario: function(scenario) {
      this.activeScenario = scenario;
    },
    setActiveRequest: function(request) {
      this.activeRequest = request;
    },
    scrollTo (ref) {
      if (this.sidebarIsActive) {
        this.sidebarIsActive = false;
      }
      this.$refs[ref].scrollIntoView(this.scrollOptions !== null ? this.scrollOptions : this.alignToTop);
    },
    toggleSidebar: function() {
      this.sidebarIsActive = !this.sidebarIsActive;
    },
    onFocus(name) {
      this.focus = name;
    },
    onBlur() {
      this.focus = '';
    },
    setFocus(name) {
      this.$refs[name].focus();
    },
    scaleIt: function (refName, scaleSize) {
      this.$refs[refName].setAttribute(
        'style',
        'transform: scale(' + scaleSize + ',' + scaleSize + ')'
      );
    },
    closeSuccessWindow() {
      this.request1send = false;
      this.request2send = false;
    },
    clearForm() {
      this.name = '';
      this.phone = '';
      this.email = '';
      this.message = '';
    },
    send1() {
      $.ajax({
        url: '/request.php',
        type: "POST",
        data: {
          name: this.name,
          email: this.email,
          phone: this.phone,
          message: this.message
        },
        statusCode: {
          404: () => {
            alert('error');
          },
          200: () => {
            this.request1send = true;
            this.clearForm();
          }
        }
      });
    },
    send2() {
      $.ajax({
        url: '/request.php',
        type: "POST",
        data: {
          name: this.name,
          email: this.email,
          phone: this.phone,
          message: this.message
        },
        statusCode: {
          404: () => {
            alert('error');
          },
          200: () => {
            this.request2send = true;
            this.clearForm();
          }
        }
      });
    },
    authSso() {
      authSso();
    }
  },
  mounted: function () {
    if (!getAuthCookie()) {
      if (window.location.search) {
        const params = getUrlQueryParams();
      
        fetchData('/sso/auth', params, (response) => {
          setAuthCookie(response.data.auth);
          window.location.replace('/tokens.html');
          // this.auth = response.data.auth;
          // fetchData('/user/info', {auth: response.data.auth}, (response) => {
          //   this.userInfo = response.data;
          // });
        })
      }
    };
    if (getAuthCookie()) {
      fetchData('/user/info', {auth: getAuthCookie()}, (response) => {
        this.userInfo = response.data;
      });
    }
  },
  watch: {
    sidebarIsActive: (val) => {
      if (val) {
        document.getElementsByTagName('body')[0].classList.add('sidebarIsActive');
      } else {
        document.getElementsByTagName('body')[0].classList.remove('sidebarIsActive');
      }
    }
  }
});

window.onload = function() {
  // LOGO & OTON ANIMATION
  const calculateScale = (val) => val < 0 ? Math.abs(0.0008 * val) + 1 : 1;
  const calculateScaleDecr = (val) => {
    if (val < 0) {
      const res = 1 - Math.abs(0.003 * val);
      return res > 0.6 ? res : 0.6;
    }
    return 1;
  };

  const circle1 = document.getElementById('round_1');
  const circle2 = document.getElementById('round_2');
  const circle3 = document.getElementById('round_3');
  const circle4 = document.getElementById('round_4');
  const circle5 = document.getElementById('round_5');
  const circle6 = document.getElementById('round_6');

  const otonBlock = document.getElementById('oton-letters');

  const scrolled1 = getCoords(circle1).top;
  const scrolled2 = getCoords(circle2).top;
  const scrolled3 = getCoords(circle3).top;
  const scrolled4 = getCoords(circle4).top;
  const scrolled5 = getCoords(circle5).top;
  const scrolled6 = getCoords(circle6).top;

  const otonOffset = getCoords(otonBlock).top;
  

  window.onscroll = function() {
    let scrolled = window.pageYOffset || document.documentElement.scrollTop;
    const letters = document.getElementsByClassName('oton__letterPic');

    circle1.style.cssText = 'transform: scale(' + calculateScale(scrolled1 - scrolled) + ')';
    circle2.style.cssText = 'transform: scale(' + calculateScale(scrolled2 + 50 - scrolled) + ')';
    circle3.style.cssText = 'transform: scale(' + calculateScale(scrolled3 + 100 - scrolled) + ')';
    circle4.style.cssText = 'transform: scale(' + calculateScale(scrolled4 + 150 - scrolled) + ')';
    circle5.style.cssText = 'transform: scale(' + calculateScale(scrolled5 + 200 - scrolled) + ')';
    circle6.style.cssText = 'transform: scale(' + calculateScale(scrolled6 + 250 - scrolled) + ')';
    
    if (scrolled > 1000) {
      circle1.style.cssText = 'transform: scale(1)';
      circle2.style.cssText = 'transform: scale(1)';
      circle3.style.cssText = 'transform: scale(1)';
      circle4.style.cssText = 'transform: scale(1)';
      circle5.style.cssText = 'transform: scale(1)';
      circle6.style.cssText = 'transform: scale(1)';
    }
    
    for (var i = 0; i < letters.length; i++) {
      letters[i].style.cssText = 'transform: scale(' + calculateScaleDecr(otonOffset - 100 - scrolled) + ')';
    }
  };
  
}