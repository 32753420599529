const phrases = {
  ru: {
    navWhitePaper: 'White paper',
    navMessage: 'Оставить заявку',
    navProblems: 'Решение проблем',
    marketplace: 'Маркетплейс',
    roadMap: 'Road map',
    team: 'Команда',
    partners: 'Партнеры',
    platformTitle: 'Платформа для рынка <br/> партнерского маркетинга, построенная с использованием </br> блокчейн технологий',
    platformText: 'Объединяем компании, партнеров, производителей товаров и покупателей в единую открытую децентрализованную сеть, которая способна значительно изменить и усовершенствовать индустрию партнерского маркетинга и прямых продаж.',
    knowMore: 'Узнать больше',
    downloadWP: 'Скачать White paper',
    inRussian: 'На русском языке',
    inEnglish: 'In English',
    problemsTitle: 'Проблемы рынка и их решения',
    problemsText: 'Централизованный принцип работы компаний, использующих партнерский маркетинг приводит к ряду проблем, которые могут быть решены применением технологии блокчейн и формированием комплексной экосистемы.',
    and: 'и',
    problem1: 'Защищенность партнера',
    problem2: 'Непрозрачность начислений',
    problem3: 'Завышенная статистика',
    problem4: 'Партнер не владеет своими средствами',
    problem5: 'Нехватка ресурсов',
    problem6: 'Прямая зависимость от настроения разработчиков',
    problemText1: 'Владельцы компаний могут в любой момент исключить партнера из финансового потока, перенаправив распределение прибыли в собственных интересах.',
    problemText2: 'Процесс распределения прибыли по сети партнеров контролируется компанией, при этом при совершении продажи партнеры могут не получить вознаграждение.',
    problemText3: 'Компании фальсифицируют данные о доходах партнеров, чтобы привлечь новых партнеров, расширить компанию и увеличить продажи.',
    problemText4: 'Большинство компаний начисляют вознаграждения партнерам в личные кабинеты с использованием своего ПО, при этом партнеры не владеют своими средствами и не имеют гарантий, что смогут вывести их и использовать по своему усмотрению в любой момент.',
    problemText5: 'Выход на рынок новых компаний и производителей связан с преодолением трудностей по разработке программного обеспечения и формирования отделов продаж и маркетинга для привлечения потребителей. Многие производители не обладают ресурсами для решения этих задач и продвижения товаров.',
    problemText6: 'Часто случается, что на начальном этапе у компаний недостаточно ресурсов, чтобы грамотно оформить отношения с компанией разработчиков системы партнерского маркетинга. Поэтому право интеллектуальной собственности и исходный код остается под контролем компании разработчика, что приводит к тому, что разработчики начинают диктовать свои условия, вплоть для изъятия компании.',
    solutionText1: 'Публичный блокчейн обеспечивает прозрачность и контроль учета всех финансовых потоков. Партнер, компании и производители могут проверить данные в блокчейне и убедиться в справедливости полученной прибыли. Новые партнеры могут также проверить все данные в открытом доступе и оценить перспективы присоединения к компании, будучи уверенными в размере потенциального дохода.',
    solutionText2: 'Использование блокчейн-технологий не только делает прозрачными все транзакции между участниками, но и позволяет выполнять трансграничные платежи, расширяя географию и пользовательскую базу компаний.',
    solutionText3: 'Компании получают доступ к достоверным данным в блокчейне, анализ которых позволит получать информацию о продажах и работе партнеров для оптимизации бизнес-процессов и повышения прибыли.',
    solutionText4: 'В децентрализованной системе никто не контролирует доступ к средствам. Распределение вознаграждений между партнерами инициируется автоматически при возникновении определенных запрограммированных условий, например, при продаже продукции или при добавлении нового партнера.',
    solutionText5: 'Экосистема OTON предлагает участникам необходимые инструменты для запуска бизнеса. OTON решает проблему непрозрачности распределения вознаграждений, предоставляет готовое техническое решение для систем учета продаж и личными кабинетами партнеров, а также позволяет получить доступ к широкой международной аудитории партнеров.',
    solutionText6: 'Децентрализованная инфраструктура OTON, с открытым исходным кодом, позволяет работать независимо от команды разработчиков, таким способом компании имеют доступ к своей базе данных, к коду и техническим решениям.',
    marketplaceHeadText: 'Центральным звеном экосистемы станет маркетплейс — торговая площадка, которая свяжет производителей, компании, партнеров и покупателей между собой.',
    marketplaceReg: 'При регистрации на маркетплейсе для каждого пользователя создается уникальный адрес в блокчейне. Полная история транзакций для каждого адреса будет надежно храниться в блокчейне.',
    marketplacePrice: 'Базовая стоимость товара и услуг на маркетплейсе указывается в фиатной валюте и токенах OTON. Стоимость товара и услуг в криптовалюте может меняться в зависимости от изменений курса.',
    paymentTypeOton: 'Токены OTON',
    cryptocurrency: 'Криптовалюта',
    fiatCurrency: 'Фиатные средства',
    goodWay: 'Удобные способы оплаты',
    marketplaceParticipants: 'В маркетплейсе участвуют',
    endCustomers: 'Конечные покупатели',
    distributors: 'партнеры',
    developers: 'Производители товаров и услуг',
    customersText: 'выбирают размещенные на маркетплейсе товары, совершают покупку товаров и услуг, выбирая удобный способ оплаты.',
    distribText: 'рекомендуют товары и услуги и в случае совершения продажи получают вознаграждение в токенах OTON.',
    devText: 'производители подают заявку на маркетплейс и могут добавить на площадку свою продукцию. В случае продажи продукции на маркетплейсе начисление средств участникам сделки производится автоматически.',
    mainScenario: 'Основные сценарии',
    howToBuy: 'Как клиент может купить товар или услугу на маркетплейсе?',
    howToBecomeDistributor: 'Как клиент может  стать партнером на маркетплейсе?',
    howToPlaceProduct: 'Как производитель может добавить свой товар или услугу на маркетплейс?',
    scenariesText11: 'Зайти на сайт маркетплейса без реферальной ссылки партнера и зарегистрироваться. При регистрации создается адрес в блокчейне и привязанный к нему личный кабинет пользователя. После этого пользователь может приобрести нужную продукцию или услуги',
    scenariesText12: 'Зайти на сайт маркетплейса с помощью реферальной ссылки партнера и зарегистрироваться. При регистрации создается адрес в блокчейне и привязанный к нему личный кабинет пользователя. После этого пользователь может приобрести нужный товар или услугу. При совершении покупки заданный процент от стоимости товара или услуги автоматически распределяется по сети указанного партнера в токенах проекта OTON',
    scenariesText13: 'Если пользователь уже имеет адрес в блокчейне, он сможет зайти на сайт маркетплейса и авторизоваться, указав свой адрес. После этого пользователь может приобрести нужный товар или услугу',
    scenariesText21: 'Зайти на сайт маркетплейса или компании-партнера',
    scenariesText22: 'Зарегистрироваться и получить адрес в блокчейне и привязанный к нему личный кабинет на маркетплейсе',
    scenariesText23: 'Приобрести уровень доступа и стать участником сообщества партнеров',
    scenariesText24: 'Рекомендовать товары и услуги пользователям и получать прозрачные вознаграждения в токенах OTON в случае покупки',
    scenariesText31: 'Подать заявку на размещение товара или услуг на сайте мар- кетплейса',
    scenariesText32: 'Менеджеры обработают заявку и свяжутся с производителем для уточнения деталей, прове- дения интервью, расчета эконо- мической составляющей',
    scenariesText33: 'В случае соответствия товара или услуги всем необходимым требованиям, соответствующий товар или услуга добавляется на маркетплейс',
    scenariesText34: 'По факту продажи товара или услуги средства, предназначен- ные производителю, начисляют- ся ему автоматически',
    otonFull: 'Полное описание экосистемы OTON, описание блокчейна, план работы и другая информация в одном документе.',
    download: 'Скачать',
    feedback: 'Обратная связь',
    feedbackText: 'Для подключения к маркеплейсу компании или продукт оставьте свои контакты.',
    name: 'Ваше имя',
    email: 'Электронная почта',
    phone: 'Телефон',
    mailMessage: 'Ваше сообщение',
    send: 'Отправить',
    fullPdf: 'Полное описание в PDF',
    otonUnite: 'Oton объединяет партнеров, производителей товаров и покупателей в единую открытую децентрализованную сеть, которая способна значительно изменить и усовершенствовать индустрию партнерского маркетинга и прямых продаж.',
    more: 'Подробнее',
    earlier: 'Раньше',
    paymentUsers: 'Пользователи маркетплейса могут выбирать удобный способ оплаты представленных товаров и услуг.',
    participantsOfMarketplace: 'Участники маркетплейса',
    networkCompanies: 'Компании',
    developersText: 'физические лица или компании, которые занимаются производством продукции. Производители подают заявку на маркетплейс и могут добавить на площадку свою продукцию. В случае продажи продукции на маркетплейсе начисление средств участникам сделки производится автоматически.',
    incorrectFormat: 'Формат некорректен',
    roadMap1: 'Техническая разработка блокчейна OTON;',
    roadMap2: 'Юридическая проработка проекта;',
    roadMap3: 'Запуск тестовой сети блокчейна TestNet;',
    roadMap4: 'Техническая разработка Маркетплейса;',
    roadMap5: 'Разработка модульного конструктора систем партнерского маркетинга;',
    roadMap6: 'Тестирование возможности подключения новых компаний к экосистеме блокчейна OTON;',
    roadMap7: 'Тестирование модульного конструктора систем партнерского маркетинга;',
    roadMap8: 'Тестирование и Аудит кода блокчейна OTON.',
    roadMap9: 'Запуск основной рабочей сети блокчейна MainNet;',
    roadMap10: 'Начало работы маркетплейса;',
    roadMap11: 'Релиз мобильного приложения;',
    roadMap12: 'Запуск возможности подключения новых компаний к экосистеме блокчейна OTON;',
    roadMap13: 'Запуск модульного конструктора системы партнерского маркетинга для компаний.',
    roadMap14: 'Начало работы децентрализованной системы управления проектом — ДАО.',
    wantToSell: 'Хочу продавать свои продукты',
    asDeveloper: 'Как производитель',
    asCompany: 'Как компания',
    sent: 'Заявка отправлена',
    thanxForRequest: 'Спасибо за заявку. В ближайшее время мы свяжемся с вами.',
    buyOtonTokens: 'Купить OTON токены',
    homePage: 'Главная',
    logout: 'Выход',
    Balance: 'Баланс',
    BalanceOton: 'Баланс OTON',
    tokensPacketsOTON: 'Пакеты токенов OTON',
    bonusDependsFromPacket: 'Бонус зависит от пакета. Выше пакет — больше бонус.',
    toBuy: 'Купить',
    onlyForVIP: 'Только для партнеров с пакетом VIP',
    buyVIP: 'Купить VIP',
    otonTokensRetail: 'Токены OTON в розницу',
    tokensLeft: 'Токенов осталось',
    tokensBought: 'Токенов купили',
    tokensCount: 'Количество токенов',
    yourBalance: 'Ваш баланс',
    toPaySum: 'К оплате',
    tokensPriceFormed: 'Стоимость токена формируется на основании биржевой цены.',
    average24Price: 'Средняя стоимость токена за 24 часа: ',
    twoFA: 'Двухфакторная аутентификация',
    enterPass: 'Введите пароль',
    enter2FA: 'Введите код 2FA для подтверждения операции',
    enterPass: 'Введите пароль для подтверждения операции',
    Confirm: 'Подтвердить',
    bannerTitle: 'Пакеты токенов OTON',
    bannerText: 'Двойной бонус при покупке любого пакета',
    listening: 'Листинг токенов OTON на биржах',
    'дней': 'дней',
    'дня': 'дня',
    'день': 'день',
    goKyc: "Пройдите верификацию KYC, чтобы купить токены",
    toKyc: "Пройти KYC",
    getAuth: "Авторизоваться через OTON"
  },
  en: {
    navWhitePaper: 'White paper',
    and: 'и',
    navMessage: 'Leave a request',
    navProblems: 'Problems solutions',
    marketplace: 'Marketplace',
    roadMap: 'Road map',
    team: 'Team',
    more: 'More',
    partners: 'Partners',
    platformTitle: 'Platform for market of <br/> affiliate marketing, built with using of </br> blockchain technologies',
    platformText: 'We unite companies, using affiliate marketing, partners, manufacturers and customers into a single open decentralized network that can significantly change and improve the industry of affiliate marketing and direct sales.',
    knowMore: 'Learn more',
    downloadWP: 'Download White paper',
    inRussian: 'In Russian',
    inEnglish: 'In English',
    problemsTitle: 'Market problems and their solutions',
    problemsText: 'Centralized work principle of companies, using affiliate marketing leads to a number of issues, that can be solved by applying blockchain technology and shaping complex ecosystem.',
    and: 'and',
    problem1: 'Partner security',
    problem2: 'Non-transparency of deposits',
    problem3: 'Overstated statistics',
    problem4: 'Partner doesn’t own its funds',
    problem5: 'Lack of resources',
    problem6: 'Direct dependency on developers mood',
    problemText1: 'Companies owners can exclude partner from cashflow at any moment and redirect profit allocation in their own interests',
    problemText2: 'Process of profit allocation at partners network is controlled by company, still partners may not get reward after selling.',
    problemText3: 'Companies fake partner revenue data to attract new partners, expand the company, and increase sales.',
    problemText4: 'Most companies award bonuses to partners in personal accounts using their software, while partners do not own their funds and have no guarantees that they can withdraw them and use them as they want when they want.',
    problemText5: 'Entering the market of new companies and manufacturers is associated with overcoming difficulties in developing software and forming sales and marketing departments to attract customers. Many manufacturers do not have the resources to solve these problems and promote products.',
    problemText6: 'It often happens that at the initial stage, the companies do not have enough resources to competently formalize the relationship with the affiliate marketing systems development company. Therefore, the intellectual property rights and the source code remains under the control of the developer’s company, which leads to developers dictating their own conditions, up to the withdrawal of the company',
    solutionText1: 'Public blockchain provides transparency and control over all the financial flows. The partner, companies and manufacturers can check the data on the blockchain and make sure that the profit received is fair. New partners can also check all data in public access and evaluate perspectives of joining the company, being confident in the amount of potential income.',
    solutionText2: 'Use of blockchain technologies not only makes all transactions between participants transparent, but also allows you to make cross-border payments, expanding the geography and user base of companies',
    solutionText3: 'Companies get access to reliable data in the blockchain, analysing which allows to receive information about sales and work of partners to optimize business processes and increase profits',
    solutionText4: 'In a decentralized system, no one controls access to funds. Distribution of awards between partners is initiated automatically when certain programmed conditions occur, for example, when selling products or adding a new partner.',
    solutionText5: 'OTON Ecosystem offers participants the necessary tools to launch a business. OTON solves the problem of non-transparent distribution of awards, provides a ready-made technical solution for sales accounting systems and personal accounts of partners, and also allows access to a wide international audience of partners.',
    solutionText6: 'Decentralized infrastructure of OTON is open source,it allows you to work independently from the development team, this way companies have access to their database, to the code and technical solutions.',
    marketplaceHeadText: 'Сenter link of the ecosystem will be the marketplace - a trading space that connects manufacturers, companies, partners and customers.',
    marketplaceReg: 'When registering at the marketplace for each user a unique address in the blockchain is created. Full transaction history for each address will be securely stored on the blockchain.',
    marketplacePrice: 'Base cost of goods and services on the marketplace is indicated in fiat currency and OTON tokens. Cost of goods and services in cryptocurrency may vary depending on changes in the exchange rate',
    paymentTypeOton: 'OTON tokens',
    cryptocurrency: 'Crypto currency',
    fiatCurrency: 'Fiat currencies',
    goodWay: 'Convenient payment methods',
    marketplaceParticipants: 'Participating in the marketplace',
    endCustomers: 'End consumers',
    distributors: 'Partners',
    developers: 'Manufacturers of goods and services',
    customersText: 'choose goods from the marketplace, make purchases of goods and services, choosing a convenient payment method',
    distribText: 'recommend goods and services and in the case of a sale are rewarded in OTON tokens',
    devText: 'manufacturers apply for marketplace and can add their products to the site. In the case of selling products on the marketplace, funds are charged automatically to the participants of the transaction',
    mainScenario: 'Main scenarios',
    howToBuy: 'How client can buy goods or services at the marketplace?',
    howToBecomeDistributor: 'How client can become a partner at the marketplace?',
    howToPlaceProduct: 'How manufacturer can add their goods or services to the marketplace?',
    scenariesText11: "Go to the marketplace site without a partner's referral link and register. During registration, an address is created in the blockchain and a user's personal account linked to it. After that user can purchase desired products or services.",
    scenariesText12: "Enter the marketplace website using the partner's referral link and register. During registration, an address is created in the blockchain and a user's personal account is linked to it. After that user can purchase desired product or service. After a purchase, the specified percentage of a product or service cost is automatically distributed over the network of the specified partner in the OTON project tokens",
    scenariesText13: 'If user already has a blockchain address he can enter the marketplace website and login with his address. Afterwards user can purchase any goods or services needed.',
    scenariesText21: 'Enter the site of the marketplace or affiliate partner-company',
    scenariesText22: 'Register and get an address on the blockchain and a personal account linked to it on the marketplace',
    scenariesText23: 'Purchase access level and become partner community member.',
    scenariesText24: 'Recommend goods and services to users and get transparent rewards in OTON tokens in case of sale.',
    scenariesText31: 'Apply for goods or services placement at the marketplace',
    scenariesText32: 'Managers will process it and connect with the manufacturer to clarify details, interview them and calculate economic components',
    scenariesText33: 'In case the product or service meets all the necessary requirements, that product or service is added to the marketplace.',
    scenariesText34: 'Upon the sale of a product or services, the funds intended for the manufacturer are deposited to him automatically.',
    otonFull: 'Full description of OTON ecosystem, blockchain description, roadmap and other information - in a single document.',
    download: 'Download',
    feedback: 'Feedback',
    feedbackText: 'To connect your goods or services to marketplace of company leave your contacts.',
    name: 'Your name',
    email: 'Email',
    phone: 'Phone',
    mailMessage: 'Your message',
    send: 'Send',
    fullPdf: 'Full description in PDF',
    earlier: 'Earlier',
    paymentUsers: 'Marketplace users can choose a convenient way to pay for goods and services presented.',
    participantsOfMarketplace: 'Marketplace participants',
    networkCompanies: 'Companies',
    developersText: 'individuals or companies that manufacture products. Manufacturers apply for the marketplace listing and can add their products to the platform. In the case of the sale of their products on the marketplace, funds are credited to all the participants in the transaction automatically.',
    incorrectFormat: 'Incorrect format',
    sent: 'Application sent',
    otonUnite: 'Oton unites affiliates, product manufacturers and customers into a single open decentralized network that can significantly change and improve the affiliate marketing and direct sales industry.',
    roadMap1: 'Technical development of OTON blockchain;',
    roadMap2: 'Legal side of the project;',
    roadMap3: 'Launch of blockchain TestNet;',
    roadMap4: 'Technical development of Marketplace;',
    roadMap5: 'Development of modular affiliate marketing systems construction kit;',
    roadMap6: 'Testing of ability to connect new companies to OTON blockchain ecosystem;',
    roadMap7: 'Testing of modular affiliate marketing systems construction kit;',
    roadMap8: 'Testing and Audit of OTON blockchain code.',
    roadMap9: 'Launch of blockchain MainNet;',
    roadMap10: 'Marketplace launch;',
    roadMap11: 'Mobile app release;',
    roadMap12: 'Launch of ability to connect new companies to OTON blockchain ecosystem;',
    roadMap13: 'Launch of modular affiliate marketing systems construction kit for companies.',
    roadMap14: 'Launch of decentralized project management system - DAO.',
    wantToSell: 'I want to sell my products',
    asDeveloper: 'As developer',
    asCompany: 'As company',
    thanxForRequest: 'Thank you for the application. In the near future we will contact you.',
    buyOtonTokens: 'Buy OTON tokens',
    homePage: 'Home page',
    logout: 'Log out',
    Balance: 'Balance',
    BalanceOton: 'OTON balance',
    tokensPacketsOTON: 'Token packets OTON',
    bonusDependsFromPacket: 'The bonus depends on the package. Higher package - more bonus.',
    toBuy: 'Buy',
    onlyForVIP: 'Only for partners with VIP packet',
    buyVIP: 'Buy VIP',
    otonTokensRetail: 'OTON Retail Tokens',
    tokensLeft: 'Tokens left',
    tokensBought: 'Tokens bought',
    tokensCount: 'Number of tokens',
    yourBalance: 'Your balance',
    toPaySum: 'To pay',
    tokensPriceFormed: 'The cost of the token is formed on the basis of the exchange price.',
    average24Price: 'Average token cost per 24 hours: ',
    twoFA: 'Two-factor authentication',
    enterPass: 'Enter password',
    enter2FA: 'Enter 2FA code to confirm operation',
    enterPass: 'Enter the password to confirm the operation',
    Confirm: 'Confirm',
    bannerTitle: 'OTON token packages',
    bannerText: 'Double bonus when buying any package',
    listening: 'Listing of OTON tokens on exchanges',
    'дней': 'days',
    'дня': 'days',
    'день': 'days',
    goKyc: "Pass KYC verification to purchase tokens",
    toKyc: "Pass KYC",
    getAuth: "Auth via OTON"
  }
}

export default phrases;